import * as React from "react";
import { useState, useCallback, useEffect } from "react";
import { Button } from "@mui/material";
import SupportModal from "../components/support-modal";
import PortalPopup from "../components/portal-popup";
import styled, { keyframes } from "styled-components";
import Drawers from "../components/drawers";
import PortalDrawer from "../components/portal-drawer";

const Continlogo2a1Icon = styled.img`
  width: 4.875rem;
  position: relative;
  height: 2.375rem;
  overflow: hidden;
  flex-shrink: 0;
`;
const LocouiLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const Button1 = styled.div`
  position: relative;
  font-size: var(--text-tiny-link-size);
  line-height: 1.25rem;
  font-family: var(--text-medium);
  color: var(--primary-main);
  text-align: center;
`;
const Button2 = styled.button`
  cursor: pointer;
  border: 1px solid var(--primary-main);
  padding: var(--padding-7xs) var(--padding-base);
  background-color: transparent;
  border-radius: var(--br-7xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: var(--color-gainsboro-100);
  }
`;
const Button3 = styled(Button)`
  position: relative;
`;
const Button4 = styled.button`
  cursor: pointer;
  border: none;
  padding: var(--padding-7xs) var(--padding-base);
  background-color: transparent;
  border-radius: var(--br-7xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: var(--color-gainsboro-100);
  }
`;
const Button5 = styled.div`
  position: relative;
  font-size: var(--text-tiny-link-size);
  line-height: 1.25rem;
  font-family: var(--text-medium);
  color: var(--dark-gray);
  text-align: center;
`;
const NavigationMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-xs);
  @media screen and (max-width: 960px) {
    display: none;
  }
`;
const HamburgerMenuIcon = styled.img`
  height: 1.5rem;
  width: 1.5rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  cursor: pointer;
  @media screen and (max-width: 960px) {
    display: flex;
  }
`;
const NotificationBellIcon = styled.img`
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
`;
const ProfilePictureIcon = styled.img`
  width: 2.25rem;
  border-radius: var(--br-19xl);
  height: 2.25rem;
  object-fit: cover;
`;
const AccountSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
`;
const NavigationRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 2.25rem;
  @media screen and (max-width: 420px) {
    display: flex;
  }
`;
const TopContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const TopHeader = styled.header`
  align-self: stretch;
  background-color: var(--dominant);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xl) var(--padding-61xl);
  box-sizing: border-box;
  position: sticky;
  width: 100%;
  top: 0;
  background: #ffffff;
  z-index: 100;
  @media screen and (max-width: 1200px) {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
  @media screen and (max-width: 960px) {
    padding-left: var(--padding-5xl);
    padding-right: var(--padding-5xl);
    box-sizing: border-box;
  }
`;
const Continlogo2a1Icon1 = styled.img`
  width: 61.144rem;
  position: absolute;
  margin: 0 !important;
  top: -8.956rem;
  left: -11.506rem;
  height: 56.294rem;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
  z-index: 0;
`;
const animationSlideInLeft = keyframes`
    0% {
        transform: translateX(-200px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
  `;
const TheActiveAlignment = styled.div`
  width: 40.688rem;
  position: relative;
  line-height: 1.875rem;
  display: none;
  @media screen and (max-width: 960px) {
    text-align: center;
  }
`;
const ProvenTrackRecord = styled.span``;
const Bodies = styled.span`
  color: #3c3358;
`;
const ProvenTrackRecordContainer = styled.b`
  align-self: stretch;
  position: relative;
  font-size: var(--h1a-size);
  line-height: 120%;
  font-family: var(--h2a);
  @media screen and (max-width: 960px) {
    text-align: center;
  }
`;
const ComplianceFeb = styled.div`
  width: 40.688rem;
  position: relative;
  font-size: var(--p1-size);
  letter-spacing: 0.04em;
  line-height: 1.813rem;
  font-weight: 300;
  white-space: pre-wrap;
  display: none;
  @media screen and (max-width: 960px) {
    text-align: center;
  }
`;
const HeroText = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
  @media screen and (max-width: 960px) {
    align-items: center;
    justify-content: flex-start;
  }
`;
const HeroContent = styled.div`
  width: 41.313rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0rem 0rem 0rem var(--padding-3xs);
  box-sizing: border-box;
  opacity: 0;
  z-index: 1;
  &.animate {
    animation: 1s ease 0s 1 normal forwards ${animationSlideInLeft};
  }
  @media screen and (max-width: 960px) {
    align-items: center;
    justify-content: center;
  }
`;
const animationSlideInTop = keyframes`
    0% {
        transform: translateY(-200px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
  `;
const HeroSectionsChild = styled.img`
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: var(--br-5xs);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  opacity: 0;
  z-index: 2;
  &.animate {
    animation: 0.5s ease 0s 1 normal forwards ${animationSlideInTop};
  }
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const HeroSections = styled.div`
  align-self: stretch;
  background-color: var(--dominant);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-61xl) var(--padding-81xl);
  position: relative;
  gap: var(--gap-41xl);
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
  @media screen and (max-width: 420px) {
    padding: var(--padding-31xl);
    box-sizing: border-box;
  }
`;
const TheWisdomOf = styled.b`
  line-height: 120%;
`;
const TheWisdomOfHandsOnExperie = styled.p`
  margin-block-start: 0;
  margin-block-end: 18px;
  color: var(--dark-gray);
`;
const AtActiveAlignment = styled.p`
  margin-block-start: 0;
  margin-block-end: 18px;
  font-size: var(--p1-size);
  letter-spacing: 0.04em;
  line-height: 1.813rem;
  font-weight: 300;
  font-family: var(--h3);
`;
const ExperienceAcrossIndustries = styled.p`
  margin-block-start: 0;
  margin-block-end: 18px;
  color: #242b42;
`;
const OurJourneyIs = styled.p`
  margin: 0;
  font-size: var(--p1-size);
  letter-spacing: 0.04em;
  line-height: 1.813rem;
  font-weight: 300;
  font-family: var(--h3);
`;
const CanYouImagineContainer = styled.div`
  align-self: stretch;
  position: relative;
`;
const WeKnowThis = styled.b`
  position: absolute;
  width: 100%;
  top: 30.1%;
  left: 0%;
  line-height: 120%;
  display: inline-block;
`;
const QuoteIcon = styled.img`
  position: absolute;
  height: 18.45%;
  width: 9.55%;
  top: 0%;
  right: 90.45%;
  bottom: 81.55%;
  left: 0%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
`;
const Quote = styled.div`
  align-self: stretch;
  position: relative;
  height: 12.875rem;
  color: var(--color-darkslategray-200);
`;
const KeyElementsOf = styled.b``;
const KeyElementsOfTheEssential = styled.p`
  margin-block-start: 0;
  margin-block-end: 18px;
`;
const ConfigureMicrosoftOffice = styled.span`
  font-weight: 300;
`;
const ConfigureMicrosoftOfficeMac = styled.li`
  margin-bottom: false;
`;
const ApplicationWhitelistingEnsu = styled.ol`
  margin: 0;
  font-size: inherit;
  padding-left: var(--padding-5xl);
`;
const NavigatingTheEssential = styled.p`
  margin-block-start: 0;
  margin-block-end: 18px;
  font-weight: 300;
`;
const InThePursuit = styled.p`
  margin: 0;
  font-weight: 300;
`;
const DevelopedByTheContainer = styled.div`
  width: 46.938rem;
  position: relative;
  font-size: var(--p1-size);
  letter-spacing: 0.04em;
  line-height: 1.813rem;
  display: inline-block;
  font-family: var(--h3);
`;
const TagsAppMobile = styled.b`
  width: 18.775rem;
  position: relative;
  font-size: var(--text-small-normal-size);
  text-transform: uppercase;
  display: inline-block;
  font-family: var(--text-tiny-link);
  white-space: pre-wrap;
`;
const Content = styled.div`
  width: 46.938rem;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-51xl);
`;
const PopularPosts = styled.b`
  position: relative;
  line-height: 120%;
`;
const TitleChild = styled.img`
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 0.188rem;
  flex-shrink: 0;
`;
const Title = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  font-size: var(--h3a-size);
  font-family: var(--h2a);
`;
const PostImageIcon = styled.img`
  width: 5rem;
  position: relative;
  height: 5rem;
  object-fit: cover;
`;
const ForWomenOnly = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 1.875rem;
  white-space: pre-wrap;
`;
const Mar = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-small-normal-size);
  line-height: 1.5rem;
  font-weight: 200;
  color: var(--color-lightslategray);
  display: inline-block;
  height: 1.563rem;
  flex-shrink: 0;
`;
const PostContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-10xs);
`;
const Post = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
`;
const ForWomenOnly1 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 1.875rem;
`;
const Posts = styled.div`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
`;
const Technology = styled.div`
  width: 17.688rem;
  position: relative;
  line-height: 1.875rem;
  display: inline-block;
`;
const Posts1 = styled.div`
  width: 14.375rem;
  position: relative;
  font-size: var(--text-small-normal-size);
  line-height: 1.5rem;
  font-weight: 200;
  color: var(--color-lightslategray);
  display: inline-block;
  height: 1.563rem;
  flex-shrink: 0;
`;
const LineIcon = styled.img`
  width: 22rem;
  position: relative;
  height: 0.188rem;
`;
const Div = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-10xs);
`;
const Category = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
`;
const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-41xl);
  font-size: var(--h3-size);
  color: var(--color-darkslategray-200);
  font-family: var(--h3);
`;
const BlogPost = styled.div`
  align-self: stretch;
  background-color: var(--dominant);
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-71xl) var(--padding-101xl);
  gap: var(--gap-81xl);
  font-size: var(--h3a-size);
  color: var(--color-lightslategray);
  font-family: var(--h2a);
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
const TrustedByThe = styled.div`
  width: 55.444rem;
  position: relative;
  display: inline-block;
`;
const BrandChild = styled.img`
  width: 8.881rem;
  position: relative;
  height: 0.188rem;
`;
const Compliancelogos1Icon = styled.img`
  width: 29.688rem;
  position: relative;
  height: 6.063rem;
  object-fit: cover;
  mix-blend-mode: darken;
`;
const Compliancelogos2Icon = styled.img`
  width: 30.313rem;
  position: relative;
  height: 6.063rem;
  object-fit: cover;
  mix-blend-mode: multiply;
`;
const Compliancelogos1Parent = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
`;
const Brand = styled.div`
  width: 90rem;
  background-color: var(--primary-main);
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-31xl) var(--padding-238xl);
  box-sizing: border-box;
  gap: var(--gap-10xl);
  text-align: center;
  font-size: var(--font-size-2xl);
  color: var(--dominant);
  font-family: var(--text-tiny-link);
`;
const CalloutSwitzerlandSunsetIma = styled.img`
  align-self: stretch;
  width: 20.938rem;
  position: relative;
  border-radius: var(--br-5xs);
  max-height: 100%;
  object-fit: cover;
  display: none;
  @media screen and (max-width: 960px) {
    width: 100%!importanrt;
    height: auto !important;
  }
`;
const CalloutSwitzerlandSunsetIma1 = styled.img`
  align-self: stretch;
  height: 16.706rem;
  position: relative;
  border-radius: var(--br-5xs);
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  @media screen and (max-width: 960px) {
    width: 100%!importanrt;
    height: auto !important;
  }
`;
const CalloutSwitzerlandMountains = styled.img`
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: var(--br-5xs);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  @media screen and (max-width: 960px) {
    width: 100% !important;
    height: auto !important;
  }
`;
const CalloutImagesColumn = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  @media screen and (max-width: 1200px) {
    width: 50% !important;
  }
  @media screen and (max-width: 960px) {
    width: 50% !important;
  }
`;
const CalloutImages = styled.div`
  width: 36.25rem;
  flex: 1;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mini);
  @media screen and (max-width: 420px) {
    width: 96% !important;
  }
`;
const CalloutImagesWrapper = styled.div`
  align-self: stretch;
  width: 37.5rem;
  background-color: var(--color-whitesmoke-300);
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  min-width: 37.5rem;
`;
const FrameChild = styled.img`
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 15rem;
  flex-shrink: 0;
  object-fit: cover;
`;
const DesignToCode = styled.div`
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
`;
const MinRead = styled.div`
  height: 1.125rem;
  position: relative;
  letter-spacing: -0.04em;
  display: inline-block;
`;
const MinReadWrapper = styled.div`
  border-radius: var(--br-7xs);
  background-color: var(--color-whitesmoke-200);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs) var(--padding-7xs);
  font-size: var(--text-small-normal-size);
  color: var(--color-dimgray);
`;
const DesignToCodeParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-4xs);
`;
const LocofyaiTurn = styled.b`
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-7xl);
  letter-spacing: 0.02em;
  line-height: 2.125rem;
  font-family: var(--font-alegreya);
  color: var(--studio-darkmode-exportbg-1a202e);
`;
const FrameContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
`;
const GoFromFigma = styled.div`
  align-self: stretch;
  position: relative;
  letter-spacing: 0.01em;
  line-height: 120%;
`;
const GoFromFigmaToCodeInMinimWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--studio-darkmode-exportbg-1a202e);
  font-family: var(--text-small-normal);
`;
const FrameGroup = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const FrameItem = styled.div`
  width: 24.794rem;
  position: relative;
  border-top: 0.7px solid var(--studio-lightmode-lightseparator-ecf0f6);
  box-sizing: border-box;
  height: 0.044rem;
`;
const AvatarIcon = styled.img`
  width: 2.5rem;
  position: relative;
  border-radius: 50%;
  height: 2.5rem;
  object-fit: cover;
`;
const AnnaRos = styled.div`
  width: 9.094rem;
  height: 1.313rem;
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
  display: inline-block;
`;
const PostedJustNow = styled.div`
  width: 9.094rem;
  height: 1rem;
  position: relative;
  font-size: var(--text-small-normal-size);
  font-family: var(--text-small-normal);
  color: var(--studio-darkmode-normalsolid-icons-96a2be);
  display: inline-block;
`;
const AuthorText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
`;
const ArticleAuthor = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
`;
const LineParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-smi);
  font-size: var(--font-size-mid);
  color: var(--color-dimgray);
`;
const FrameParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base);
  gap: var(--gap-smi);
`;
const RectangleParent = styled.div`
  align-self: stretch;
  border-radius: var(--br-3xs);
  background-color: var(--dominant);
  border: 0.7px solid var(--studio-lightmode-midseparator-dee3eb);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
`;
const MinReadContainer = styled.div`
  border-radius: var(--br-9xs);
  background-color: var(--color-whitesmoke-200);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs) var(--padding-7xs);
  font-size: var(--text-small-normal-size);
  color: var(--color-dimgray);
`;
const DesignToCodeGroup = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
`;
const LineDiv = styled.div`
  width: 18.919rem;
  position: relative;
  border-top: 0.7px solid var(--studio-lightmode-lightseparator-ecf0f6);
  box-sizing: border-box;
  height: 0.044rem;
`;
const LineGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-smi);
  font-size: var(--font-size-mid);
  color: var(--color-dimgray);
`;
const FrameChild1 = styled.div`
  width: 20.669rem;
  position: relative;
  border-top: 0.7px solid var(--studio-lightmode-lightseparator-ecf0f6);
  box-sizing: border-box;
  height: 0.044rem;
`;
const FrameParent1 = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--padding-base);
`;
const RectangleParent1 = styled.div`
  align-self: stretch;
  border-radius: var(--br-3xs);
  background-color: var(--dominant);
  border: 0.7px solid var(--studio-lightmode-midseparator-dee3eb);
  box-sizing: border-box;
  height: 36.188rem;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
`;
const ContentSection = styled.div`
  align-self: stretch;
  background-color: var(--dominant);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xl);
  gap: var(--gap-21xl);
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
const ContentSectionWrapper = styled.div`
  flex: 1;
  background-color: var(--color-whitesmoke-300);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  min-width: 37.5rem;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const Layouts = styled.div`
  width: 90rem;
  background-color: var(--dominant);
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  font-size: var(--btns-size);
  color: var(--studio-darkmode-maincta-457eff);
  font-family: var(--font-source-code-pro);
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
const Home = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  height: 1rem;
  position: relative;
  font-size: var(--text-small-normal-size);
  font-family: var(--text-small-normal);
  color: var(--dominant);
  text-align: center;
  display: inline-block;
`;
const FooterLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xl);
  @media screen and (max-width: 960px) {
    display: none;
  }
`;
const VectorIcon = styled.img`
  position: absolute;
  height: 96%;
  width: 96%;
  top: 2%;
  right: 2%;
  bottom: 2%;
  left: 2%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
`;
const EntypoSocialfacebookWithCi = styled.a`
  text-decoration: none;
  position: absolute;
  top: 0rem;
  left: 0rem;
  width: 1.875rem;
  height: 1.875rem;
  overflow: hidden;
`;
const EntypoSocialtwitterWithCir = styled.a`
  text-decoration: none;
  position: absolute;
  top: 0rem;
  left: 6.25rem;
  width: 1.875rem;
  height: 1.875rem;
  overflow: hidden;
`;
const EntypoSocialinstagramWithC = styled.a`
  text-decoration: none;
  position: absolute;
  top: 0rem;
  left: 3.125rem;
  width: 1.875rem;
  height: 1.875rem;
  overflow: hidden;
`;
const SocialIcons = styled.div`
  width: 8.125rem;
  position: relative;
  height: 1.875rem;
`;
const Footer = styled.div`
  align-self: stretch;
  background-color: var(--primary-main);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-13xl) var(--padding-21xl);
  @media screen and (max-width: 1200px) {
    padding: var(--padding-11xl) var(--padding-61xl);
    box-sizing: border-box;
  }
  @media screen and (max-width: 960px) {
    padding: var(--padding-10xl) var(--padding-21xl);
    box-sizing: border-box;
  }
  @media screen and (max-width: 420px) {
    padding: var(--padding-9xl) var(--padding-5xl);
    box-sizing: border-box;
  }
`;
const ExperienceRoot = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--h3-size);
  color: var(--dark-gray);
  font-family: var(--h3);
`;

const Experience = () => {
  const [isSupportModalOpen, setSupportModalOpen] = useState(false);
  const [isDrawersOpen, setDrawersOpen] = useState(false);

  const openSupportModal = useCallback(() => {
    setSupportModalOpen(true);
  }, []);

  const closeSupportModal = useCallback(() => {
    setSupportModalOpen(false);
  }, []);

  const openDrawers = useCallback(() => {
    setDrawersOpen(true);
  }, []);

  const closeDrawers = useCallback(() => {
    setDrawersOpen(false);
  }, []);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <>
      <ExperienceRoot>
        <TopHeader>
          <TopContainer>
            <LocouiLogo>
              <Continlogo2a1Icon alt="" src="/continlogo2a-1.svg" />
            </LocouiLogo>
            <NavigationRight>
              <NavigationMenu>
                <Button2 onClick={openSupportModal}>
                  <Button1>Request a Demo</Button1>
                </Button2>
                <Button4>
                  <Button3
                    disableElevation={true}
                    color="primary"
                    variant="text"
                    sx={{ borderRadius: "0px 0px 0px 0px" }}
                  >
                    Search
                  </Button3>
                </Button4>
                <Button4>
                  <Button5>Solutions</Button5>
                </Button4>
                <Button4>
                  <Button5>Partners</Button5>
                </Button4>
                <Button4>
                  <Button5>Pricing</Button5>
                </Button4>
                <Button4>
                  <Button5>Company</Button5>
                </Button4>
              </NavigationMenu>
              <AccountSection>
                <HamburgerMenuIcon
                  alt=""
                  src="/notification2.svg"
                  onClick={openDrawers}
                />
                <NotificationBellIcon alt="" src="/notification11.svg" />
                <ProfilePictureIcon alt="" src="/top_avatar1@2x.png" />
              </AccountSection>
            </NavigationRight>
          </TopContainer>
        </TopHeader>
        <HeroSections>
          <Continlogo2a1Icon1 alt="" src="/continlogo2a-13@2x.png" />
          <HeroContent data-animate-on-scroll>
            <HeroText>
              <TheActiveAlignment>
                The Active Alignment Journal
              </TheActiveAlignment>
              <ProvenTrackRecordContainer>
                <ProvenTrackRecord>{`Proven Track Record: Decades of Excellence in Collaboration with Leading Corporations and Government `}</ProvenTrackRecord>
                <Bodies>Bodies</Bodies>
              </ProvenTrackRecordContainer>
              <ComplianceFeb>Compliance - Feb 10, 2024</ComplianceFeb>
            </HeroText>
          </HeroContent>
          <HeroSectionsChild
            alt=""
            src="/rectangle-12@2x.png"
            data-animate-on-scroll
          />
        </HeroSections>
        <BlogPost>
          <Content>
            <CanYouImagineContainer>
              <TheWisdomOfHandsOnExperie>
                <TheWisdomOf>The Wisdom of hands on Experience</TheWisdomOf>
              </TheWisdomOfHandsOnExperie>
              <AtActiveAlignment>
                At Active Alignment, we take pride in our extensive experience
                collaborating with both industry-leading corporations and
                esteemed government bodies. With a collective wealth of
                expertise accumulated over several decades, our team stands as a
                beacon of excellence in the realms of compliance, cybersecurity,
                and strategic advisory services.
              </AtActiveAlignment>
              <ExperienceAcrossIndustries>
                <TheWisdomOf>Experience Across Industries:</TheWisdomOf>
              </ExperienceAcrossIndustries>
              <OurJourneyIs>
                Our journey is marked by successful partnerships with diverse
                industries, ranging from finance and healthcare to technology
                and government sectors. This breadth of experience has equipped
                us with a deep understanding of the nuanced challenges each
                sector faces and the ability to tailor our solutions
                accordingly.
              </OurJourneyIs>
            </CanYouImagineContainer>
            <Quote>
              <WeKnowThis>
                We know this in our gut, but what can we do about it? How can we
                motivate ourselves? One of the most difficult aspects of
                achieving success is staying motivated over the long haul.
              </WeKnowThis>
              <QuoteIcon alt="" src="/quote1@2x.png" />
            </Quote>
            <DevelopedByTheContainer>
              <KeyElementsOfTheEssential>
                <KeyElementsOf>
                  Key Elements of the Essential Eight
                </KeyElementsOf>
              </KeyElementsOfTheEssential>
              <ApplicationWhitelistingEnsu>
                <ConfigureMicrosoftOfficeMac>
                  <ConfigureMicrosoftOffice>
                    Application Whitelisting: Ensure that only approved
                    applications run on your systems, mitigating the risk of
                    unauthorized and potentially malicious software.
                  </ConfigureMicrosoftOffice>
                </ConfigureMicrosoftOfficeMac>
                <ConfigureMicrosoftOfficeMac>
                  <ConfigureMicrosoftOffice>
                    Configure Microsoft Office Macro Settings: Fine-tune your
                    Microsoft Office settings to prevent the execution of
                    malicious macros, a common entry point for cyber threats.
                  </ConfigureMicrosoftOffice>
                </ConfigureMicrosoftOfficeMac>
                <ConfigureMicrosoftOfficeMac>
                  <ConfigureMicrosoftOffice>
                    Patch Applications: Regularly update and patch applications
                    to eliminate vulnerabilities that could be exploited by
                    cyber adversaries.
                  </ConfigureMicrosoftOffice>
                </ConfigureMicrosoftOfficeMac>
                <ConfigureMicrosoftOfficeMac>
                  <ConfigureMicrosoftOffice>
                    Configure Microsoft Office Macro Settings: Implement robust
                    email filtering solutions to block phishing emails and
                    prevent malicious attachments from compromising your
                    systems.
                  </ConfigureMicrosoftOffice>
                </ConfigureMicrosoftOfficeMac>
                <ConfigureMicrosoftOfficeMac>
                  <ConfigureMicrosoftOffice>
                    User Application Hardening: Enhance user security by
                    restricting access and privileges, minimizing the potential
                    impact of cyber threats.
                  </ConfigureMicrosoftOffice>
                </ConfigureMicrosoftOfficeMac>
                <ConfigureMicrosoftOfficeMac>
                  <ConfigureMicrosoftOffice>
                    Use Multi-Factor Authentication: Bolster access controls by
                    implementing multi-factor authentication, adding an extra
                    layer of defense against unauthorized access.
                  </ConfigureMicrosoftOffice>
                </ConfigureMicrosoftOfficeMac>
                <ConfigureMicrosoftOfficeMac>
                  <ConfigureMicrosoftOffice>
                    Backup Your Data: Guard against data loss by establishing a
                    comprehensive backup strategy, ensuring the availability of
                    critical information in the event of a security incident.
                  </ConfigureMicrosoftOffice>
                </ConfigureMicrosoftOfficeMac>
                <ConfigureMicrosoftOfficeMac>
                  <ConfigureMicrosoftOffice>
                    Implementing Incident Detection and Response: Develop and
                    implement an effective incident detection and response
                    capability, allowing swift action in the face of
                    cybersecurity incidents.
                  </ConfigureMicrosoftOffice>
                </ConfigureMicrosoftOfficeMac>
              </ApplicationWhitelistingEnsu>
              <KeyElementsOfTheEssential>
                <KeyElementsOf>Your Path to Cyber Resilience</KeyElementsOf>
              </KeyElementsOfTheEssential>
              <NavigatingTheEssential>
                Navigating the Essential Eight is not just a compliance mandate;
                it's a strategic investment in cyber resilience. Organizations
                that embrace these guidelines position themselves to proactively
                counter emerging threats, safeguard sensitive data, and maintain
                the trust of their stakeholders.
              </NavigatingTheEssential>
              <NavigatingTheEssential>
                In the coming weeks, we will delve deeper into each of the
                Essential Eight strategies, offering practical insights and
                actionable steps to empower your organization's cybersecurity
                journey. Stay tuned for a comprehensive exploration of each
                element and discover how to fortify your defenses in an
                ever-changing digital landscape.
              </NavigatingTheEssential>
              <InThePursuit>
                In the pursuit of cybersecurity excellence, let the Essential
                Eight be your compass, guiding you towards a future of
                heightened resilience and unwavering security.
              </InThePursuit>
            </DevelopedByTheContainer>
            <TagsAppMobile>Tags: Compliance, E8, Story</TagsAppMobile>
          </Content>
          <Sidebar>
            <Posts>
              <Title>
                <PopularPosts>Popular posts</PopularPosts>
                <TitleChild alt="" src="/line-6.svg" />
              </Title>
              <Post>
                <PostImageIcon alt="" src="/post-image@2x.png" />
                <PostContent>
                  <ForWomenOnly>{`Common Compliance Pitfalls and How to Avoid Them  `}</ForWomenOnly>
                  <Mar>Mar 23, 2018</Mar>
                </PostContent>
              </Post>
              <Post>
                <PostImageIcon alt="" src="/post-image1@2x.png" />
                <PostContent>
                  <ForWomenOnly1>
                    The Role of Culture in Compliance: Building a
                    Security-Conscious Organisation
                  </ForWomenOnly1>
                  <Mar>Mar 23, 2018</Mar>
                </PostContent>
              </Post>
              <Post>
                <PostImageIcon alt="" src="/post-image2@2x.png" />
                <PostContent>
                  <ForWomenOnly1>
                    The Future of Compliance Technology: Trends to Watch
                  </ForWomenOnly1>
                  <Mar>Mar 23, 2018</Mar>
                </PostContent>
              </Post>
              <Post>
                <PostImageIcon alt="" src="/post-image@2x.png" />
                <PostContent>
                  <ForWomenOnly1>
                    For Women Only Your Computer Usage Could Cost You Your Job
                  </ForWomenOnly1>
                  <Mar>Mar 23, 2018</Mar>
                </PostContent>
              </Post>
            </Posts>
            <Category>
              <Title>
                <PopularPosts>Category</PopularPosts>
                <TitleChild alt="" src="/line-61.svg" />
              </Title>
              <Div>
                <Technology>Technology</Technology>
                <Posts1>3 posts</Posts1>
                <LineIcon alt="" src="/line.svg" />
              </Div>
              <Div>
                <Technology>Application</Technology>
                <Posts1>3 posts</Posts1>
                <LineIcon alt="" src="/line.svg" />
              </Div>
              <Div>
                <Technology>Lifestyle</Technology>
                <Posts1>3 posts</Posts1>
                <LineIcon alt="" src="/line.svg" />
              </Div>
              <Div>
                <Technology>Education</Technology>
                <Posts1>3 posts</Posts1>
                <LineIcon alt="" src="/line.svg" />
              </Div>
            </Category>
          </Sidebar>
        </BlogPost>
        <Brand>
          <TrustedByThe>
            Continuous compliance for mulitple frameworks
          </TrustedByThe>
          <BrandChild alt="" src="/line-3.svg" />
          <Compliancelogos1Parent>
            <Compliancelogos1Icon alt="" src="/compliancelogos-11@2x.png" />
            <Compliancelogos2Icon alt="" src="/compliancelogos-2@2x.png" />
          </Compliancelogos1Parent>
          <BrandChild alt="" src="/line-3.svg" />
        </Brand>
        <Layouts>
          <CalloutImagesWrapper>
            <CalloutImages>
              <CalloutSwitzerlandSunsetIma
                alt=""
                src="/callout-switzerland-sunset-image@2x.png"
              />
              <CalloutImagesColumn>
                <CalloutSwitzerlandSunsetIma1
                  alt=""
                  src="/callout-switzerland-sunset-image1@2x.png"
                />
                <CalloutSwitzerlandMountains
                  alt=""
                  src="/callout-switzerland-mountains-popup2@2x.png"
                />
              </CalloutImagesColumn>
              <CalloutImagesColumn>
                <CalloutSwitzerlandMountains
                  alt=""
                  src="/callout-switzerland-mountains-popup1@2x.png"
                />
                <CalloutSwitzerlandSunsetIma1
                  alt=""
                  src="/callout-switzerland-sunset-image2@2x.png"
                />
              </CalloutImagesColumn>
            </CalloutImages>
          </CalloutImagesWrapper>
          <ContentSectionWrapper>
            <ContentSection>
              <RectangleParent>
                <FrameChild alt="" src="/rectangle-478@2x.png" />
                <FrameParent>
                  <FrameGroup>
                    <FrameContainer>
                      <DesignToCodeParent>
                        <DesignToCode>Design to code</DesignToCode>
                        <MinReadWrapper>
                          <MinRead>3 min read</MinRead>
                        </MinReadWrapper>
                      </DesignToCodeParent>
                      <LocofyaiTurn>
                        Locofy.ai – Turn Figma to Code!
                      </LocofyaiTurn>
                    </FrameContainer>
                    <GoFromFigmaToCodeInMinimWrapper>
                      <GoFromFigma>
                        Go from Figma to code in minimum amount of time using
                        Locofy. Take your..
                      </GoFromFigma>
                    </GoFromFigmaToCodeInMinimWrapper>
                  </FrameGroup>
                  <LineParent>
                    <FrameItem />
                    <ArticleAuthor>
                      <AvatarIcon alt="" src="/avatar@2x.png" />
                      <AuthorText>
                        <AnnaRos>Anna Rosé</AnnaRos>
                        <PostedJustNow>Posted just now</PostedJustNow>
                      </AuthorText>
                    </ArticleAuthor>
                  </LineParent>
                </FrameParent>
              </RectangleParent>
              <RectangleParent>
                <FrameChild alt="" src="/rectangle-4781@2x.png" />
                <FrameParent>
                  <FrameGroup>
                    <FrameContainer>
                      <DesignToCodeGroup>
                        <DesignToCode>Design to code</DesignToCode>
                        <MinReadContainer>
                          <MinRead>2 min read</MinRead>
                        </MinReadContainer>
                      </DesignToCodeGroup>
                      <LocofyaiTurn>Figma for Developers</LocofyaiTurn>
                    </FrameContainer>
                    <GoFromFigmaToCodeInMinimWrapper>
                      <GoFromFigma>
                        Developers often believe they will be off to a flying
                        start if they skip the designing process and jump
                        directly to code.
                      </GoFromFigma>
                    </GoFromFigmaToCodeInMinimWrapper>
                  </FrameGroup>
                  <LineGroup>
                    <LineDiv />
                    <ArticleAuthor>
                      <AvatarIcon alt="" src="/avatar1@2x.png" />
                      <AuthorText>
                        <AnnaRos>Justin Chen</AnnaRos>
                        <PostedJustNow>Posted on Sep 6, 2022</PostedJustNow>
                      </AuthorText>
                    </ArticleAuthor>
                  </LineGroup>
                </FrameParent>
              </RectangleParent>
              <RectangleParent>
                <FrameChild alt="" src="/rectangle-4782@2x.png" />
                <FrameParent>
                  <FrameGroup>
                    <FrameContainer>
                      <DesignToCodeGroup>
                        <DesignToCode>Integrations</DesignToCode>
                        <MinReadContainer>
                          <MinRead>2 min read</MinRead>
                        </MinReadContainer>
                      </DesignToCodeGroup>
                      <LocofyaiTurn>
                        Best UI library to use in Figma
                      </LocofyaiTurn>
                    </FrameContainer>
                    <GoFromFigmaToCodeInMinimWrapper>
                      <GoFromFigma>
                        Find out what UI library suit your product the most.
                        From Material UI, Bootstrap, ...
                      </GoFromFigma>
                    </GoFromFigmaToCodeInMinimWrapper>
                  </FrameGroup>
                  <LineGroup>
                    <FrameChild1 />
                    <ArticleAuthor>
                      <AvatarIcon alt="" src="/avatar2@2x.png" />
                      <AuthorText>
                        <AnnaRos>Isaac Tan</AnnaRos>
                        <PostedJustNow>Posted on Sep 5, 2022</PostedJustNow>
                      </AuthorText>
                    </ArticleAuthor>
                  </LineGroup>
                </FrameParent>
              </RectangleParent>
              <RectangleParent1>
                <FrameChild alt="" src="/rectangle-4782@2x.png" />
                <FrameParent1>
                  <FrameGroup>
                    <FrameContainer>
                      <DesignToCodeGroup>
                        <DesignToCode>Integrations</DesignToCode>
                        <MinReadContainer>
                          <MinRead>2 min read</MinRead>
                        </MinReadContainer>
                      </DesignToCodeGroup>
                      <LocofyaiTurn>
                        Best UI library to use in Figma
                      </LocofyaiTurn>
                    </FrameContainer>
                    <GoFromFigmaToCodeInMinimWrapper>
                      <GoFromFigma>
                        Find out what UI library suit your product the most.
                        From Material UI, Bootstrap, ...
                      </GoFromFigma>
                    </GoFromFigmaToCodeInMinimWrapper>
                  </FrameGroup>
                  <LineGroup>
                    <FrameChild1 />
                    <ArticleAuthor>
                      <AvatarIcon alt="" src="/avatar2@2x.png" />
                      <AuthorText>
                        <AnnaRos>Isaac Tan</AnnaRos>
                        <PostedJustNow>Posted on Sep 5, 2022</PostedJustNow>
                      </AuthorText>
                    </ArticleAuthor>
                  </LineGroup>
                </FrameParent1>
              </RectangleParent1>
            </ContentSection>
          </ContentSectionWrapper>
          <ContentSectionWrapper>
            <ContentSection>
              <RectangleParent>
                <FrameChild alt="" src="/rectangle-478@2x.png" />
                <FrameParent>
                  <FrameGroup>
                    <FrameContainer>
                      <DesignToCodeParent>
                        <DesignToCode>Design to code</DesignToCode>
                        <MinReadWrapper>
                          <MinRead>3 min read</MinRead>
                        </MinReadWrapper>
                      </DesignToCodeParent>
                      <LocofyaiTurn>
                        Locofy.ai – Turn Figma to Code!
                      </LocofyaiTurn>
                    </FrameContainer>
                    <GoFromFigmaToCodeInMinimWrapper>
                      <GoFromFigma>
                        Go from Figma to code in minimum amount of time using
                        Locofy. Take your..
                      </GoFromFigma>
                    </GoFromFigmaToCodeInMinimWrapper>
                  </FrameGroup>
                  <LineParent>
                    <FrameItem />
                    <ArticleAuthor>
                      <AvatarIcon alt="" src="/avatar@2x.png" />
                      <AuthorText>
                        <AnnaRos>Anna Rosé</AnnaRos>
                        <PostedJustNow>Posted just now</PostedJustNow>
                      </AuthorText>
                    </ArticleAuthor>
                  </LineParent>
                </FrameParent>
              </RectangleParent>
              <RectangleParent>
                <FrameChild alt="" src="/rectangle-4781@2x.png" />
                <FrameParent>
                  <FrameGroup>
                    <FrameContainer>
                      <DesignToCodeGroup>
                        <DesignToCode>Design to code</DesignToCode>
                        <MinReadContainer>
                          <MinRead>2 min read</MinRead>
                        </MinReadContainer>
                      </DesignToCodeGroup>
                      <LocofyaiTurn>Figma for Developers</LocofyaiTurn>
                    </FrameContainer>
                    <GoFromFigmaToCodeInMinimWrapper>
                      <GoFromFigma>
                        Developers often believe they will be off to a flying
                        start if they skip the designing process and jump
                        directly to code.
                      </GoFromFigma>
                    </GoFromFigmaToCodeInMinimWrapper>
                  </FrameGroup>
                  <LineGroup>
                    <LineDiv />
                    <ArticleAuthor>
                      <AvatarIcon alt="" src="/avatar1@2x.png" />
                      <AuthorText>
                        <AnnaRos>Justin Chen</AnnaRos>
                        <PostedJustNow>Posted on Sep 6, 2022</PostedJustNow>
                      </AuthorText>
                    </ArticleAuthor>
                  </LineGroup>
                </FrameParent>
              </RectangleParent>
              <RectangleParent>
                <FrameChild alt="" src="/rectangle-4782@2x.png" />
                <FrameParent>
                  <FrameGroup>
                    <FrameContainer>
                      <DesignToCodeGroup>
                        <DesignToCode>Integrations</DesignToCode>
                        <MinReadContainer>
                          <MinRead>2 min read</MinRead>
                        </MinReadContainer>
                      </DesignToCodeGroup>
                      <LocofyaiTurn>
                        Best UI library to use in Figma
                      </LocofyaiTurn>
                    </FrameContainer>
                    <GoFromFigmaToCodeInMinimWrapper>
                      <GoFromFigma>
                        Find out what UI library suit your product the most.
                        From Material UI, Bootstrap, ...
                      </GoFromFigma>
                    </GoFromFigmaToCodeInMinimWrapper>
                  </FrameGroup>
                  <LineGroup>
                    <FrameChild1 />
                    <ArticleAuthor>
                      <AvatarIcon alt="" src="/avatar2@2x.png" />
                      <AuthorText>
                        <AnnaRos>Isaac Tan</AnnaRos>
                        <PostedJustNow>Posted on Sep 5, 2022</PostedJustNow>
                      </AuthorText>
                    </ArticleAuthor>
                  </LineGroup>
                </FrameParent>
              </RectangleParent>
              <RectangleParent1>
                <FrameChild alt="" src="/rectangle-4782@2x.png" />
                <FrameParent1>
                  <FrameGroup>
                    <FrameContainer>
                      <DesignToCodeGroup>
                        <DesignToCode>Integrations</DesignToCode>
                        <MinReadContainer>
                          <MinRead>2 min read</MinRead>
                        </MinReadContainer>
                      </DesignToCodeGroup>
                      <LocofyaiTurn>
                        Best UI library to use in Figma
                      </LocofyaiTurn>
                    </FrameContainer>
                    <GoFromFigmaToCodeInMinimWrapper>
                      <GoFromFigma>
                        Find out what UI library suit your product the most.
                        From Material UI, Bootstrap, ...
                      </GoFromFigma>
                    </GoFromFigmaToCodeInMinimWrapper>
                  </FrameGroup>
                  <LineGroup>
                    <FrameChild1 />
                    <ArticleAuthor>
                      <AvatarIcon alt="" src="/avatar2@2x.png" />
                      <AuthorText>
                        <AnnaRos>Isaac Tan</AnnaRos>
                        <PostedJustNow>Posted on Sep 5, 2022</PostedJustNow>
                      </AuthorText>
                    </ArticleAuthor>
                  </LineGroup>
                </FrameParent1>
              </RectangleParent1>
            </ContentSection>
          </ContentSectionWrapper>
        </Layouts>
        <Footer>
          <TopContainer>
            <FooterLinks>
              <Home>Home</Home>
              <Home>About us</Home>
              <Home>Our Services</Home>
              <Home>Our Clients</Home>
              <Home>Contact us</Home>
            </FooterLinks>
            <SocialIcons>
              <EntypoSocialfacebookWithCi>
                <VectorIcon alt="" src="/vector.svg" />
              </EntypoSocialfacebookWithCi>
              <EntypoSocialtwitterWithCir>
                <VectorIcon alt="" src="/vector1.svg" />
              </EntypoSocialtwitterWithCir>
              <EntypoSocialinstagramWithC>
                <VectorIcon alt="" src="/vector2.svg" />
              </EntypoSocialinstagramWithC>
            </SocialIcons>
          </TopContainer>
        </Footer>
      </ExperienceRoot>
      {isSupportModalOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeSupportModal}
        >
          <SupportModal onClose={closeSupportModal} />
        </PortalPopup>
      )}
      {isDrawersOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Left"
          onOutsideClick={closeDrawers}
        >
          <Drawers onClose={closeDrawers} />
        </PortalDrawer>
      )}
    </>
  );
};

export default Experience;
