import * as React from "react";
import { useEffect } from "react";
import styled, { keyframes } from "styled-components";

const animationSlideInLeft = keyframes`
    0% {
        transform: translateX(-200px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
  `;
const LocouiLogoIcon = styled.img`
  width: 2.125rem;
  position: relative;
  border-radius: var(--br-5xs);
  height: 2.125rem;
  overflow: hidden;
  flex-shrink: 0;
`;
const LocouiIcon = styled.img`
  width: 3.581rem;
  position: relative;
  height: 0.85rem;
`;
const ByTeamLocofy = styled.div`
  position: relative;
  letter-spacing: -0.04em;
`;
const LocouiParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-9xs);
`;
const LocouiLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
`;
const Email = styled.div`
  width: 21.25rem;
  position: relative;
  line-height: 1.375rem;
  display: none;
`;
const Icon1 = styled.img`
  width: 1.125rem;
  position: relative;
  height: 1.125rem;
  overflow: hidden;
  flex-shrink: 0;
`;
const Jeraldinelocofyuiai = styled.input`
  border: none;
  outline: none;
  font-family: var(--text-medium);
  font-size: var(--btns-size);
  background-color: transparent;
  flex: 1;
  position: relative;
  line-height: 1.5rem;
  color: var(--light-gray);
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const IconParent = styled.div`
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--studio-darkmode-allwhite-ffffff);
  border: 1px solid var(--light-border);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-sm);
  gap: var(--gap-3xs);
`;
const ThisIsA = styled.div`
  width: 21.25rem;
  position: relative;
  font-size: var(--text-tiny-link-size);
  line-height: 1.25rem;
  color: var(--gray);
  display: none;
`;
const InputFieldBase = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
`;
const InputField = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--p2-size);
  color: var(--dark-gray);
`;
const LocouiLogoParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
`;
const SolidIcon = styled.img`
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
`;
const Home = styled.div`
  position: relative;
  font-size: var(--btns-size);
  line-height: 1.5rem;
  font-weight: 600;
  font-family: var(--text-medium);
  color: var(--primary-main);
  text-align: left;
`;
const SolidIconParent = styled.button`
  cursor: pointer;
  border: none;
  padding: var(--padding-sm) var(--padding-xs);
  background-color: var(--light-background);
  align-self: stretch;
  border-radius: var(--br-7xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-lg);
`;
const Dashboard = styled.div`
  position: relative;
  font-size: var(--btns-size);
  line-height: 1.5rem;
  font-family: var(--text-medium);
  color: var(--gray);
  text-align: left;
`;
const SolidIconGroup = styled.button`
  cursor: pointer;
  border: none;
  padding: var(--padding-sm) var(--padding-xs);
  background-color: transparent;
  align-self: stretch;
  border-radius: var(--br-7xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-lg);
`;
const FrameChild = styled.img`
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
`;
const FrameDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-lg);
`;
const Text1 = styled.div`
  position: relative;
  font-size: var(--p2-size);
  line-height: 1.375rem;
  font-family: var(--text-medium);
  color: var(--studio-darkmode-allwhite-ffffff);
  text-align: center;
`;
const Badge = styled.div`
  border-radius: var(--br-base);
  background-color: var(--confirm);
  height: 1.375rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-11xs) var(--padding-5xs);
  box-sizing: border-box;
`;
const FrameParent = styled.button`
  cursor: pointer;
  border: none;
  padding: var(--padding-sm) var(--padding-xs);
  background-color: transparent;
  align-self: stretch;
  border-radius: var(--br-7xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const FrameParent1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
`;
const FrameItem = styled.img`
  width: 2.688rem;
  position: relative;
  border-radius: 50%;
  height: 2.688rem;
  object-fit: cover;
`;
const JohnDoe = styled.b`
  align-self: stretch;
  position: relative;
`;
const Johndoegmailcom = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--btns-size);
  line-height: 1.5rem;
  color: var(--gray);
`;
const JohnDoeParent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const EllipseParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-41xl) 0rem 0rem;
  gap: var(--gap-xl);
  font-size: var(--p1-size);
  color: var(--dark-gray);
`;
const DrawersRoot = styled.div`
  width: 21.5rem;
  position: relative;
  background-color: var(--studio-darkmode-allwhite-ffffff);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xl) var(--padding-6xl);
  box-sizing: border-box;
  gap: var(--gap-xl);
  opacity: 0;
  height: 100%;
  max-width: 90%;
  overflow: auto;
  text-align: left;
  font-size: 0.688rem;
  color: var(--studio-darkmode-popuplabels-5e6a86);
  font-family: var(--text-medium);
  &.animate {
    animation: 0.25s ease 0s 1 normal forwards ${animationSlideInLeft};
  }
`;

const Drawers = ({ onClose }) => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <DrawersRoot data-animate-on-scroll>
      <LocouiLogoParent>
        <LocouiLogo>
          <LocouiLogoIcon alt="" src="/locouilogo.svg" />
          <LocouiParent>
            <LocouiIcon alt="" src="/locoui.svg" />
            <ByTeamLocofy>by Team Locofy</ByTeamLocofy>
          </LocouiParent>
        </LocouiLogo>
        <InputField>
          <InputFieldBase>
            <Email>First Name</Email>
            <IconParent>
              <Icon1 alt="" src="/icon1.svg" />
              <Jeraldinelocofyuiai
                placeholder="jeraldine@locofyui.ai"
                type="text"
              />
            </IconParent>
            <ThisIsA>This is a hint text for the user</ThisIsA>
          </InputFieldBase>
        </InputField>
      </LocouiLogoParent>
      <FrameParent1>
        <InputFieldBase>
          <SolidIconParent>
            <SolidIcon alt="" src="/solidicon.svg" />
            <Home>Home</Home>
          </SolidIconParent>
          <SolidIconGroup>
            <SolidIcon alt="" src="/solidicon1.svg" />
            <Dashboard>Dashboard</Dashboard>
          </SolidIconGroup>
          <SolidIconGroup>
            <SolidIcon alt="" src="/solidicon2.svg" />
            <Dashboard>Products</Dashboard>
          </SolidIconGroup>
          <SolidIconGroup>
            <SolidIcon alt="" src="/solidicon3.svg" />
            <Dashboard>Events</Dashboard>
          </SolidIconGroup>
          <SolidIconGroup>
            <SolidIcon alt="" src="/solidicon4.svg" />
            <Dashboard>Users</Dashboard>
          </SolidIconGroup>
        </InputFieldBase>
        <FrameChild alt="" src="/vector-7.svg" />
        <InputFieldBase>
          <SolidIconGroup>
            <SolidIcon alt="" src="/solidicon5.svg" />
            <Dashboard>Sales</Dashboard>
          </SolidIconGroup>
          <SolidIconGroup>
            <SolidIcon alt="" src="/solidicon2.svg" />
            <Dashboard>Discounts</Dashboard>
          </SolidIconGroup>
          <SolidIconGroup>
            <SolidIcon alt="" src="/solidicon6.svg" />
            <Dashboard>Payments</Dashboard>
          </SolidIconGroup>
          <FrameParent>
            <FrameDiv>
              <SolidIcon alt="" src="/solidicon7.svg" />
              <Dashboard>Messages</Dashboard>
            </FrameDiv>
            <Badge>
              <Text1>213</Text1>
            </Badge>
          </FrameParent>
          <SolidIconGroup>
            <SolidIcon alt="" src="/solidicon8.svg" />
            <Dashboard>Settings</Dashboard>
          </SolidIconGroup>
        </InputFieldBase>
      </FrameParent1>
      <EllipseParent>
        <FrameItem alt="" src="/ellipse-6@2x.png" />
        <JohnDoeParent>
          <JohnDoe>John Doe</JohnDoe>
          <Johndoegmailcom>john.doe@gmail.com</Johndoegmailcom>
        </JohnDoeParent>
      </EllipseParent>
    </DrawersRoot>
  );
};

export default Drawers;
